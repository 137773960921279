.swiper {
  @apply bg-white;

  & :global(.swiper-pagination-bullet) {
    background-color: #000;
  }
}

.navigation {
  @apply absolute top-0 flex items-center justify-between w-full h-full px-2 md:px-4;
}

.button {
  @apply z-10 w-12 h-12 text-center bg-white rounded-full cursor-pointer;
  @apply hover:bg-black-25;

  & svg {
    @apply w-[17px] h-auto;
  }

  &:disabled {
    @apply pointer-events-none border-black-50;

    & .leftArrow,
    & .rightArrow {
      @apply text-black-50;
    }
  }
}

.leftArrow,
.rightArrow {
  @apply w-3 h-3 mx-auto transform rotate-180;
}

.rightArrow {
  @apply rotate-0;
}
