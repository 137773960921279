.article {
  & p {
    @apply mb-7;
  }

  & h2 {
    @apply pb-6 text-2xl sm:text-2.3xl lg:text-2.5xl;
  }

  & a {
    @apply underline;
  }

  & ol,
  & ul {
    @apply list-disc pl-5 pb-7 leading-7;
  }

  & ol {
    @apply list-decimal;
  }

  & li {
    @apply pl-3 mb-2;
  }

  & h3,
  & h4,
  & h5,
  & h6 {
    @apply pb-6 text-xl sm:text-1.5xl lg:text-2xl;
  }

  & > *:last-of-type {
    @apply mb-0;
  }
}

.slide {
  width: 100%;
  height: 100%;
}
